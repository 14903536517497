.logo-header_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
    margin-bottom: 3vh;
    opacity: 80%;
}

.logo-header_logo-image {
    height: 15vh;
}