/* LoadingScreen.css */

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000; /* Match this with your app background to avoid color flash */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    visibility: visible; /* Ensure it's visible by default */
  }
  
  .loading-screen.fade-out {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease-in-out, visibility 1s 1s; /* Hide after fade-out */
  }
  
  .loading-logo {
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .loading-video {
    width: 100px;
    height: auto;
  }
  