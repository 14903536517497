.navbar_desktop-container {
    position: relative;
    display: flex;
    width: 100vw;
    z-index: 100000;
    height: 5vh;
    margin-bottom: 2vh;
}

.navbar_desktop-button {
    flex-grow: 1;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    border-style: hidden;
    font-family: 'Courier New', Courier, monospace;
    font-size: 2vw;
}

.navbar_mobile-container {
    position: fixed;
    top: 0;
    width: 75vw;
    height: 100vh;
    z-index: 1000000;
    pointer-events: none; 
}

.navbar_mobile-menu-toggle {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 8vw;
    margin-top: 1vh;
    margin-left: 1vw;
    z-index: 1001;
    padding-right: 10vw;
    padding-bottom: 5vh;
    pointer-events: auto;
}

.navbar_mobile-menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.navbar_mobile-button {
    display: block;
    width: 100%;
    border: none;
    background-color: transparent;
    text-align: center;
    cursor: pointer;
    margin-top: 5vh;
    font-size: 7vw;
}

.navbar_mobile-container.open .navbar_mobile-menu {
    pointer-events: auto; /* Allow clicks */
}

.navbar_desktop-button, .navbar_mobile-button {
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Inherit text color */
    display: inline-block; /* Ensure the link behaves like a button */
    /* Add any other button styling here */
}

.navbar_mobile-button {
    padding: 0.5vh; /* Adjust padding for mobile buttons */
}